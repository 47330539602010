import React from 'react';
import CustomHtmlPrompt from '../../../components/inputs/CustomHtmlPrompt';

class HomeThankyou extends React.Component {
  constructor(props) {
    super(props);
    // eslint-disable-next-line react/no-unused-state
    this.state = { modal: {}, nextButtonClass: 'display-none' };
  }

  render() {
    const {
      thankYouBody,
      surveyPresent,
      thankyouText,
      thankyouHtmlSafe,
      optinThankyou,
    } = this.props;

    return (
      <div id="tpi_thank_you">
        {surveyPresent ? (
          <div id="thank_you">
            <div className="page-inner-content">
              {thankyouText && (
                <div>
                  <h1 className="tpi_text_head d-block mg-15-0">
                    <CustomHtmlPrompt label={thankyouHtmlSafe} />
                  </h1>
                  <p className="tpi_text mg-0">
                    <CustomHtmlPrompt label={thankYouBody} />
                  </p>
                </div>
              )}
            </div>
          </div>
        ) : (
          <div
            /* eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex */
            tabIndex="0"
            dangerouslySetInnerHTML={{ __html: optinThankyou }}
          />
        )}
      </div>
    );
  }
}

export default HomeThankyou;
