import React from 'react';
import CustomHtmlPrompt from '../../../components/inputs/CustomHtmlPrompt';

class AtriumThankyou extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      surveyPresent,
      id,
      thankYou,
      thankyouHtmlSafe,
      thankyouText,
      thankYouText1,
      thankYouText2,
      closeWarning,
      optinThankyou,
      thankYouText3,
    } = this.props;
    return (
      <div id="thank_you_page_inner">
        <div id="thank_you">
          {surveyPresent ? (
            <div>
              <div>
                <div>
                  <h1>{thankYou}</h1>
                  <p>
                    <b>{thankyouText && thankyouHtmlSafe}</b>
                  </p>
                  <br />
                  <br />
                  <p>
                    <CustomHtmlPrompt label={thankYouText1} />
                    <br />
                    <br />
                    <CustomHtmlPrompt label={thankYouText2} />
                  </p>
                </div>
              </div>
              <p className="close-warning">{closeWarning}</p>
              <div>
                <div className="click_here">
                  <CustomHtmlPrompt label={thankYouText3} component="h2" />
                </div>
              </div>
            </div>
          ) : (
            <div
              /* eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex */
              tabIndex="0"
              dangerouslySetInnerHTML={{ __html: optinThankyou }}
            />
          )}
        </div>
      </div>
    );
  }
}

export default AtriumThankyou;
