import React from 'react';

const InTakeRoleText = ({ provider, stringValues }) =>
  ['coordinator', 'intern_provider'].includes(provider?.intake_appt_role) && (
    <p className="color-plum">
      <strong>
        <i>
          {provider?.intake_appt_role === 'coordinator'
            ? stringValues?.intake_coordinator_info
            : stringValues?.intake_intern_info}
        </i>
      </strong>
    </p>
  );
export default InTakeRoleText;
