import React from 'react';
import { BrowserRouter as Router, Route, Prompt } from 'react-router-dom';
import moment from 'moment';

import { withAuthenticationProvider } from '../../../context/authentication';
import { withUtilitiesProvider } from '../../../context/utilities';
import { ModalProvider } from '../../../context/modal';
import LeftContentPage from './components/left_section';
import RightSection from './components/RightSection';
import Assessment from './assessment';
import SlotBooking from './slot_booking';
import Notify from './notify';
import PatientConsent from './patient_consent';
import Welcome from './welcome';
import Forms from './forms';
import Thankyou from './thankyou';
import Confirm from './confirm';
import Configure from './forms';
import MemberSelfServe from './member_self_serve';
import AppointmentSuccess from './appointment_success';
import Timer from '../../components/Timer';

import './style.scss';
import './components/style.scss';
import style from './patient_view.module.scss';
import { addPatientLog } from '../../../services/patient';

const components = [
  { name: Confirm, path: '/confirm' },
  { name: Thankyou, path: '/thankyou' },
  { name: Thankyou, path: '/tpi/thankyou' },
  { name: Thankyou, path: '/nw/thankyou' },
  { name: Thankyou, path: '/satss/thankyou' },
  { name: Thankyou, path: '/lifestance/thankyou' },
  { name: Thankyou, path: '/home/thankyou' },
  { name: Thankyou, path: '/beacon/thankyou' },
  { name: Thankyou, path: '/atrium/thankyou' },
  { name: Thankyou, path: '/post/thankyou' },
  { name: Forms, path: '/patient_details' },
  { name: Forms, path: '/register' },
  { name: Forms, path: '/register/mrn' },
  { name: Forms, path: '/register/patient_details' },
  { name: Forms, path: '/register/pgt' },
  { name: Notify, path: '/no_configuration_found' },
  { name: Welcome, path: '/welcome' },
  { name: Assessment, path: '/survey' },
  { name: SlotBooking, path: '/call_centers/providers' },
  { name: Configure, path: '/configure' },
  { name: Notify, path: '/time_out' },
  { name: Forms, path: '/phone_number_match' },
  { name: Forms, path: '/tpi/register' },
  { name: Forms, path: '/protected_not_authorized' },
  { name: AppointmentSuccess, path: '/admin/dfd/success' },
  { name: Welcome, path: '/' },
  { name: PatientConsent, path: '/patient_consent' },
  { name: MemberSelfServe, path: '/member_self_serve/:id' },
];

class SurveyApp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      idleTimeLimit: props.timeOut || 120000, // timeout in milliseconds
      timeOutWarningTime: 20,
      assessmentData: {},
      hasTimedOut: false,
      shouldBlockNavigation: false,
      headerFilterOpen: false,
      timeOutDialogue: false,
    };
    this.timeRef = React.createRef(Date.now());
    this.modalRef = React.createRef();
  }

  resetTimer = (forceReset) => {
    const { timeOutDialogue } = this.state;
    if (timeOutDialogue !== true || forceReset === true) {
      this.timeRef.current = Date.now();
    }
  };

  handleTimeoutRedirection = () => {
    const { mode, pageName, authenticatedPatientId } = this.props;
    if (pageName === 'thankyou' || pageName === 'assessment') {
      return;
    }
    if (authenticatedPatientId || mode === 'dfd') {
      window.location.href = '/message?message=mss_screener_timeout';
    } else if (mode === 'home') {
      window.location.href = '/time_out';
    } else {
      window.location.href = '/welcome';
    }
  };

  setDocumentTitle = () => {
    const { pageName } = this.props;
    let pageTitle = '';
    switch (pageName) {
      case 'welcome':
        pageTitle = 'Lucet Welcome Page';
        break;
      case 'slot_booking':
        pageTitle = 'Lucet Appointment Booking Page';
        break;
      case 'assessment':
        pageTitle = 'Lucet Assessment';
        break;
      case 'thankyou':
        pageTitle = 'Lucet Survey Complete page';
        break;
      case 'member_self_serve':
        pageTitle = 'Lucet Member Dashboard';
        break;
      case 'member_self_serve_success':
        pageTitle = 'Lucet Member Appointment Success';
        break;

      default:
        pageTitle = 'Lucet';
        break;
    }
    document.title = pageTitle;
  };

  postTimeout = () => {
    const { timeOut } = this.props;
    clearInterval(this.interval);
    this.setState({ hasTimedOut: true });
    if (timeOut) {
      this.setState({ shouldBlockNavigation: false }, () => {
        this.handleUnLoadNavigation();
        // window.location.href = idleURL;
        this.handleTimeoutRedirection();
      });
    }
  };

  handleTimeout = () => {
    const { patientId, surveyId, pageName } = this.props;
    const { idleTimeLimit } = this.state;
    const idleSince = Date.now() - this.timeRef.current; // get difference in milliseconds;
    if (patientId) {
      addPatientLog(patientId, {
        survey_id: surveyId,
        idle_time_limit: idleTimeLimit,
        idle_time: idleSince,
        operation:
          pageName === 'assessment' ? 'assessment_timed_out' : 'timed_out',
      }).then(() => {
        this.postTimeout();
      });
    } else {
      this.postTimeout();
    }
  };

  leavePage = () => {
    const { timeOut } = this.props;
    const { idleTimeLimit, timeOutWarningTime, timeOutDialogue } = this.state;
    const idleSince = Date.now() - this.timeRef.current; // get difference in milliseconds;
    if (idleSince > idleTimeLimit) {
      if (!timeOutDialogue) {
        this.handleTimeout();
      }
    }
    if (
      timeOut &&
      !timeOutDialogue &&
      idleSince + timeOutWarningTime * 1000 > idleTimeLimit
    ) {
      this.showTimeoutWarning();
    }
  };

  showTimeoutWarning = async () => {
    const { stringValues } = this.props;
    const { timeOutWarningTime } = this.state;
    if (!this.modalRef.current) {
      return;
    }
    this.setState({ timeOutDialogue: true });
    const { confirmDialogue } = this.modalRef.current;
    const timeOutInModal = setTimeout(() => {
      this.handleTimeout();
    }, timeOutWarningTime * 1000);
    const response = await confirmDialogue(
      <div>
        <h4>{stringValues.timeout_warning_header_text}</h4>
        {stringValues.timeout_warning_body_text_first}{' '}
        <strong>
          <Timer time={timeOutWarningTime} /> {stringValues.seconds}
        </strong>{' '}
        {stringValues.timeout_warning_body_text_last}
      </div>,
      {
        cancelText: stringValues.cancel,
        okText: stringValues.timeout_warning_ok_button_text,
        customProps: { disableLucetTheme: true },
      },
    );
    this.setState({ timeOutDialogue: false });
    if (response) {
      clearTimeout(timeOutInModal);
      this.resetTimer(true); // force reset timer
    } else {
      this.handleTimeout();
    }
  };

  _onMouseMove = (e) => {
    this.resetTimer();
  };

  _onTouchStart = (e) => {
    this.resetTimer();
  };

  _onKeyPress = (e) => {
    this.resetTimer();
  };

  setLoader = (isShown = true) => {
    this.setState({
      loading: isShown,
    });
  };

  updateAssessmentData = (newData) => {
    this.setState((prevState) => ({
      assessmentData: {
        ...prevState.assessmentData,
        ...newData,
      },
    }));
  };

  handleUnLoadNavigation = () => {
    const { shouldBlockNavigation } = this.state;
    if (shouldBlockNavigation) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = undefined;
    }
  };

  handleUnloadUpdates = (blockNavigation) => {
    const { blockNavigationOnLeave } = this.props;
    if (blockNavigationOnLeave) {
      this.setState(
        { shouldBlockNavigation: blockNavigation },
        this.handleUnLoadNavigation,
      );
    }
  };

  handleHeaderFilterToggle = (value) => {
    this.setState({
      headerFilterOpen: value,
    });
  };

  setRoutes = () => {
    const { shouldBlockNavigation, hasTimedOut, headerFilterOpen } = this.state;
    var routeList = [];
    components.forEach((item, index) => {
      const DynamicComponent = item.name;
      routeList.push(
        <Route
          exact={true}
          key={index}
          path={item.path}
          render={(props) => (
            <React.Fragment>
              <DynamicComponent
                {...props}
                {...this.props}
                setLoader={this.setLoader}
                updateAssessmentData={this.updateAssessmentData}
                handleUnloadUpdates={this.handleUnloadUpdates}
                hasTimedOut={hasTimedOut}
                headerFilterOpen={headerFilterOpen}
                handleHeaderFilterToggle={this.handleHeaderFilterToggle}
              />
              <Prompt
                when={shouldBlockNavigation}
                message="You are attempting to exit this application. Your data may be lost."
              />
            </React.Fragment>
          )}
        />,
      );
    });
    return routeList;
  };

  componentDidMount() {
    const { logoutIfIdle = true } = this.props;
    const { idleTimeLimit } = this.state;
    this.resetTimer(); // by default the ref is assigned to DOM element so we want to update it to current time
    if (logoutIfIdle && idleTimeLimit) {
      this.interval = setInterval(() => this.leavePage(), 10000);
    } else {
      clearInterval(this.interval);
    }
    this.handleUnLoadNavigation();

    this.setDocumentTitle();
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {
    const {
      pageName,
      randomizeClass,
      includeLeftPage = true,
      rebrand,
      bannerText,
      currentYear,
    } = this.props;
    const { loading, assessmentData, headerFilterOpen } = this.state;
    return (
      <div
        ref={this.timeRef}
        id={`outer-wrapper`}
        onMouseMove={this._onMouseMove.bind(this)}
        onTouchStart={this._onTouchStart.bind(this)}
        onKeyPress={this._onKeyPress.bind(this)}
        className={`${
          style.outerWrapper
        } ${pageName} ${randomizeClass} header-${includeLeftPage} ${
          rebrand ? 'rebrand' : ''
        } outer-wrapper survey-pages react-module-pages`}
        // role="main"
      >
        <ModalProvider ref={this.modalRef}>
          {includeLeftPage && (
            <LeftContentPage
              {...this.props}
              assessmentData={assessmentData}
              setLoader={this.setLoader}
              headerFilterOpen={headerFilterOpen}
              handleHeaderFilterToggle={this.handleHeaderFilterToggle}
            />
          )}
          <RightSection
            pageName={pageName}
            bannerText={bannerText}
            currentYear={currentYear}
          >
            <Router>{this.setRoutes()}</Router>
          </RightSection>
        </ModalProvider>
        {loading && (
          <div className="loader">
            <div />
          </div>
        )}
      </div>
    );
  }
}

export default withAuthenticationProvider(withUtilitiesProvider(SurveyApp));
