import clsx from 'clsx';
import React from 'react';

const WelcomeBanner = ({ styles, welcomeText, limits = {} }) => (
  <div className={clsx(styles.bannerWrapper, 'd-flex justify-between')}>
    <div className={styles.bannerInnerWrapper}>
      <div className={styles.welcomeText}>{welcomeText}!</div>
      <div className={styles.bannerText}>
        <span className={styles.expiryText}>{limits.expiryText}</span>{' '}
      </div>
    </div>
    <div className="align-items-center d-flex">
      <a
        className={clsx(styles.btnLogout, 'd-flex flex-col align-items-center')}
        href="/message?message=member_self_serve.mss_member_logged_out"
      >
        <i className="material-symbols-outlined text-4xl">logout</i>
        Logout
      </a>
    </div>
  </div>
);

export default WelcomeBanner;
