import React from "react"

class PopUp extends React.Component {
  constructor(props) {
    super(props);

    this.state = {}

  }

  render() {
    const {closeModal, saveModal, organization, popUpModal} = this.props
    return (
      <div>
      <div id="dialog" className="popup" style={{display:'none'}} role="alertdialog" aria-modal="true" aria-labelledby="dialog_head" aria-describedby="dialog_desc">
      <p id="dialog_desc">{popUpModal.pauseMsg1}<br/>
      {popUpModal.pauseMsg2}</p>
      <div className="button_section">
        <button id="save_btn" className="close_btn <%= (I18n.locale == :es || I18n.locale == :pt) ? 'close_pause' : '' %>">
        {popUpModal.saveExitBtn}
        </button>
        <button className="resume_btn <%= (I18n.locale == :es || I18n.locale == :pt) ? 'resume_pause' : '' %>">
        {popUpModal.resumeSurveyBtn}
        </button>
      </div>
    </div>
    
    <div id="dialog2" className="popup" style={{display:'none'}} role="alertdialog" aria-modal="true" aria-labelledby="dialog2_head" aria-describedby="dialog2_desc">
      <h2 id="dialog2_head" title=""></h2>
      <p>
        {popUpModal.cookiesMode ? 
          popUpModal.pauseMsgAtHome
        :
          popUpModal.pauseMsg3
        }
      </p>
      <br/>
      <p>{popUpModal.pauseMsg4}</p>
      <div className="button_section">
        <button id="close_btn_1" className="close_btn btn-redirect">
        {popUpModal.closeBtn}
        </button>
      </div>
    </div>
   <div>
  {popUpModal.modeResume &&
      <div id="dialog5" className="popup" style={{display:'none'}} role="alertdialog" aria-modal="true" aria-labelledby="dialog5_head" aria-describedby="dialog5_desc">
        <h2 id="dialog5_head" title={popUpModal.titleWelcomePopup}></h2>
        <div>
        {popUpModal.surveyProxy ? (
          <div>
          <p id="dialog5_desc"><strong>{popUpModal.welcomeBackText},
          {popUpModal.surveyLabel}.</strong> {popUpModal.pauseMsg5}Only {popUpModal.surveyName} {popUpModal.completeMsgPopup}{popUpModal.ifYouAreNot}{popUpModal.surveyName} {popUpModal.exitAssessmentPopup}</p>
          <div className="button_section">
            <button id="continue_btn" className="close_btn">
              {popUpModal.continueBtn}
            </button>
          </div>
          </div>
          ) : (
            <div>
          <p id="dialog5_desc"><strong>{popUpModal.welcomeBackText},
          {popUpModal.patientFirstName}.</strong> {popUpModal.pauseMsg5}</p>
          <div className="button_section">
            <button id="continue_btn" className="close_btn">
            {popUpModal.continueBtn}
            </button>
          </div>
          </div>
        )}
        </div>
      </div>
    }

   </div>
  
   
    
    <div id="dialog3" className="popup" style={{display:'none'}}>
      <p>{popUpModal.pauseMsg4}</p>
      <div className="button_section">
        <button id="close_btn_2" className="close_btn">
        {popUpModal.closeBtn}
        </button>
      </div>
    </div>
    
    <div id="dialog4" className="popup" style={{display:'none'}}>
      <div className="button_section">
        <button id="video_start" className="close_btn <%= (I18n.locale == :es || I18n.locale == :pt) ? 'close_pause' : '' %>"></button>
        <button id='video_resume' className="resume_btn <%= (I18n.locale == :es || I18n.locale == :pt) ? 'resume_pause' : '' %>">
        </button>
      </div>
    </div>
    </div>
    );
  }
}

export default PopUp