import React from 'react';
import clsx from 'clsx';
import ProgressSection from './ProgressSection';
import styles from './left_section.module.scss';

let debouceTimerHeader;
class LeftContentPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      debounceTime: 10,
      isMobile: false,
    };
  }

  componentDidMount() {
    const { debounceTime } = this.state;
    const debouncedHandleResize = this.debounce(
      this.resizeListener,
      debounceTime,
    );
    this.setIfMobile();
    window.removeEventListener('resize', debouncedHandleResize);
    window.addEventListener('resize', debouncedHandleResize);
  }

  setIfMobile = () => {
    const windowWidth =
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth;

    const { isMobile } = this.state;
    const currentIsMobile = windowWidth < 1235;
    // only update state if its not the same as old state
    if (isMobile !== currentIsMobile) {
      this.setState({ isMobile: currentIsMobile });
    }
  };

  updateIfMobile = () => {
    this.callWithDelay(this.setIfMobile);
  };

  resizeListener = () => {
    this.updateIfMobile();
  };

  debounce =
    (method, delayTime, ...args) =>
    // eslint-disable-next-line no-unused-vars
    (_) => {
      clearTimeout(debouceTimerHeader);
      // eslint-disable-next-line no-shadow,no-unused-vars
      debouceTimerHeader = setTimeout((_) => {
        debouceTimerHeader = null;
        method.apply(this, args);
      }, delayTime);
    };

  callWithDelay = (method, delayTime, ...args) => {
    // eslint-disable-next-line no-shadow,no-unused-vars
    setTimeout((_) => {
      debouceTimerHeader = null;
      method.apply(this, args);
    }, delayTime);
  };

  supportedLanguages() {
    const {
      supportedLanguages,
      assessmentData,
      isDFD,
      stringValues = {},
    } = this.props;
    // eslint-disable-next-line camelcase
    const additional_params =
      assessmentData && assessmentData.survey_id
        ? `id=${assessmentData.survey_id}`
        : '';
    return (
      supportedLanguages &&
      supportedLanguages.map((language, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <React.Fragment key={index}>
          {index !== 0 && (
            <span className={`divider divider-${index % 2}`}>|</span>
          )}
          <span>
            {/* eslint-disable-next-line camelcase */}
            <a href={`/select_language/${language.tag}?${additional_params}`}>
              <div className="espanol-text-inner language_link">
                {language.selection_label}
                {isDFD && '*'}
              </div>
            </a>
            {isDFD && (
              <i className={styles.languageDisclaimer}>
                *
                {stringValues.unavailable_spanish_areas_disclaimer ||
                  'some areas are not available in Spanish'}
              </i>
            )}
          </span>
        </React.Fragment>
      ))
    );
  }

  showSite = () => {
    const { pageName, site } = this.props;
    return pageName === 'welcome' && site;
  };

  logoClicked = () => {
    const { rebrand, patient } = this.props;
    if (rebrand && patient && patient.home_url) {
      window.location.href = patient.home_url;
    }
  };

  showOrgLogo = () => {
    const { assessmentLogo, logoAltName } = this.props;
    return (
      assessmentLogo && (
        <img
          alt={`${logoAltName} logo`}
          title={`${logoAltName} logo`}
          /* eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex */
          tabIndex="0"
          className=""
          src={
            assessmentLogo.includes('/')
              ? assessmentLogo
              : `/assets/${assessmentLogo}`
          }
        />
      )
    );
  };

  showSupportedLanguages = () => {
    const { supportedLanguages } = this.props;
    return (
      supportedLanguages && (
        <div className="language-selector">{this.supportedLanguages()}</div>
      )
    );
  };

  showSiteName = () => {
    const { site } = this.props;
    return <p className="siteNameP">{this.showSite() && site.name}</p>;
  };

  showBannerText = () => {
    const { bannerText } = this.props;
    return (
      bannerText && (
        <div className="header-col bannerTextContainer">
          <div
            className="bannerText"
            dangerouslySetInnerHTML={{ __html: bannerText }}
          />
        </div>
      )
    );
  };

  showTridiuumLogo = () => {
    const { tridiuumLogo = true, isDFD = false, rebrand, patient } = this.props;
    const isDFDPage = isDFD || window.location.href.includes('is_dfd=true');
    return (
      tridiuumLogo && (
        <div className={`powered_by_polaris ${isDFD ? 'is-dfd' : ''}`}>
          <a href={rebrand && patient && patient.home_url}>
            <img
              src={
                rebrand
                  ? '/assets/logos/lucet_logo.png'
                  : isDFDPage
                  ? '/assets/logos/powered_by_tridiuum_light.png'
                  : '/assets/powered_by_tridiuum.png'
              }
              alt={rebrand ? 'Lucet Health' : 'Tridiuum ONE'}
              title={rebrand ? 'Lucet Health' : 'Tridiuum ONE'}
              width="200"
            />
          </a>
        </div>
      )
    );
  };

  showProgressSection = () => {
    const { pageName, assessmentData, stringValues, mode, patient, setLoader } =
      this.props;
    return (
      (pageName === 'assessment' || pageName === 'thankyou') && (
        <ProgressSection
          /* eslint-disable-next-line react/jsx-props-no-spreading */
          {...this.props}
          assessmentData={assessmentData}
          stringValues={stringValues}
          mode={mode}
          patient={patient}
          setLoader={setLoader}
        />
      )
    );
  };

  showFilterToggle = () => {
    const { showFilter, headerFilterOpen, handleHeaderFilterToggle } =
      this.props;
    return (
      showFilter && (
        <button
          type="button"
          onClick={() => {
            handleHeaderFilterToggle(!headerFilterOpen);
          }}
          className="btn btn-plain hamburgerButton"
        >
          <span className="material-symbols-outlined">filter_list</span>
        </button>
      )
    );
  };

  lucetHeaderMobile = () => {
    const { assessmentLogo } = this.props;
    return (
      <div>
        <div className="header-container">
          <div className="assessment-logo-wrapper left-col">
            <div className="logo-and-site">
              {this.showOrgLogo()}
              {this.showSite() && (
                <div
                  className={`siteNameCont with-logo-${
                    assessmentLogo ? 'true' : 'false'
                  }`}
                >
                  {this.showSiteName()}
                </div>
              )}
            </div>
          </div>
          <div className={clsx('right-col', styles.rightCol)}>
            {this.showTridiuumLogo()}
            {this.showSupportedLanguages()}
            {this.showFilterToggle()}
          </div>
        </div>
        {this.showProgressSection()}
      </div>
    );
  };

  lucetHeaderDesktop = () => {
    const { assessmentLogo } = this.props;
    return (
      <div className="header-container">
        <div className="assessment-logo-wrapper top-col">
          <div className="logo-and-site">
            {this.showOrgLogo()}
            {this.showSite() && (
              <div
                className={`siteNameCont with-logo-${
                  assessmentLogo ? 'true' : 'false'
                }`}
              >
                {this.showSiteName()}
              </div>
            )}
          </div>
          {this.showSupportedLanguages()}
        </div>
        <div className="mid-col">{this.showBannerText()}</div>
        <div className="bottom-col">
          {this.showProgressSection()}
          {this.showTridiuumLogo()}
        </div>
      </div>
    );
  };

  lucetHeader = () => {
    const { pageName } = this.props;
    const { isMobile } = this.state;
    return (
      <header
        id="header"
        className={`${pageName}-header mss-header ${
          isMobile ? 'r-mobile' : 'r-desktop'
        }`}
      >
        {isMobile ? this.lucetHeaderMobile() : this.lucetHeaderDesktop()}
      </header>
    );
  };

  legacyHeader = () => {
    const { pageName, assessmentLogo } = this.props;
    return (
      <header id="header" className={`${pageName}-header`}>
        <div className="header-container">
          <div className="assessment-logo-wrapper">{this.showOrgLogo()}</div>
          {assessmentLogo && this.showSite() && <p className="separator" />}
          {this.showSiteName()}
          {this.showTridiuumLogo()}
          {this.showSupportedLanguages()}
          {this.showProgressSection()}
        </div>
      </header>
    );
  };

  render() {
    const { isDFD } = this.props;
    return isDFD ? this.lucetHeader() : this.legacyHeader();
  }
}

export default LeftContentPage;
